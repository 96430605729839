.blog__item {
  border: 1px solid #7c8a972e;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog__time {
  border-top: 1px solid #7c8a972e;
}

.read__more {
  color: #f9a826;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

.read__more:hover {
  color: #f9a826;
}

.blog__time span i {
  color: #f9a826;
}

.blog__author {
  color: #000d6b;
  font-weight: 600;
}

.blog__author i {
  font-weight: 500;
}

.blog__title {
  text-decoration: none;
  color: #000d6b;
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.3s;
}

.blog__title:hover {
  color: #f9a826;
}

.blog__content {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of visible lines */
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 768px) {
  .blog__time {
    padding-top: 5px !important;
  }

  .blog__time span {
    font-size: 0.7rem;
  }

  .blog__info p {
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-top: 10px;
  }

  .blog__title {
    font-size: 1.1rem;
  }

  .read__more {
    font-size: 0.7rem;
  }
}