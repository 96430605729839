.find__cars-left {
  background-color: #4874e3bf;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 35px;
}

.find__cars-left h2 {
  font-weight: 600;
  font-size: 2.8rem;
  color: white;
}




.form {

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 35px;
}




.hero__slider-section {
  position: relative;
  z-index: 10;
  margin-bottom: 200px;
}

.hero__form {
  position: absolute;
  bottom: -150px;
  z-index: 11;
  width: 100%;
}

.form__row {
  background: linear-gradient(rgb(249, 168, 38, 0.9), rgb(249, 168, 38, 0.9)),
    url("../assets/all-images/chrysler300s.png");

}

.journey__time::before {
  content: "Journey time";
  margin-right: 0.8rem;
  color: white;
}

.find__car-btn {
  background: #000000 !important;
  color: #fff !important;
  padding: 8px 15px;
  width: 100%;
  border: none;
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .find__cars-left {
    padding: 65px 22px;
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
  }

  .form__group input,
  .select__group select {
    padding: 7px 10px;
    font-size: 0.8rem;
  }

  .hero__form {
    bottom: -100px;
  }
}


/* @media only screen and (max-width: 768px) {
  .find__cars-left {
    padding: 65px 22px;
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
  }

  .form__group input,
  .select__group select {
    padding: 7px 10px;
    font-size: 0.8rem;
  }

  .hero__form {
    bottom: -100px;
  }
} */

@media only screen and (max-width: 576px) {
  .form__group {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }

  .form__group input {
    font-size: 0.7rem !important;
  }

  .select__group {
    width: 100%;
    padding-right: 10px;
    font-size: 0.7rem;
  }

  .find__car-btn {
    font-size: 0.8rem;
  }

  .find__cars-left h2 {
    font-size: 1rem;
    margin-left: 45px;
  }

  .hero__form {
    bottom: -250px;
  }

  .find__cars-left {
    background-color: #030500bf;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 35px;
  }
}